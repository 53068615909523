import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import Logo from '../assets/Header/novade-logo-square.jpg';

interface SEOProps {
  title?: string;
  description?: string;
  keywords?: string;
  image?: string;
  lang?: string;
  isTemplatePage: boolean;
}

const siteQuery = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        description
        keywords
        siteUrl
        author {
          name
          url
        }
      }
    }
  }
`;

const SEO: React.FC<SEOProps> = ({ title, description, keywords, image, lang, isTemplatePage }) => {
  const { site } = useStaticQuery(siteQuery);

  const seo = {
    lang: lang || 'en',
    title: title || site.siteMetadata.title,
    description: description || site.siteMetadata.description,
    image: `${site.siteMetadata.siteUrl}${image || Logo}`,
    url: `${site.siteMetadata.siteUrl}`,
    keywords: keywords || site.siteMetadata.keywords,
    author: site.siteMetadata.author.name
  };

  const pathname = typeof window !== 'undefined' ? window.location.pathname : '';
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null; // resolve duplicate content issues for good SEO
  const links = canonical ? [{ rel: 'canonical', href: canonical }] : [];
  const titleTemplate = isTemplatePage ? `${title} | Novade` : `${title} - ${site.siteMetadata.title}`;
  return (
    <Helmet
      htmlAttributes={{ lang: seo.lang }}
      title={seo.title}
      titleTemplate={titleTemplate}
      link={links}
      meta={[
        {
          name: 'description',
          content: seo.description
        },
        {
          name: 'image',
          content: seo.image
        },
        {
          name: 'keywords',
          content: seo.keywords
        },
        // Social Media Card - Facebook
        {
          property: 'og:title',
          content: seo.title
        },
        {
          property: 'og:description',
          content: seo.description
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: `og:url`,
          content: canonical
        },
        {
          property: 'og:image',
          content: seo.image
        },
        {
          property: 'og:image:width',
          content: '200'
        },
        {
          property: 'og:image:height',
          content: '200'
        },
        // Social Media Card - Twitter
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: `twitter:image`,
          content: seo.image
        },
        {
          name: 'twitter:creator',
          content: seo.author
        },
        {
          name: 'twitter:title',
          content: seo.title
        },
        {
          name: 'twitter:description',
          content: seo.description
        }
      ]}
    />
  );
};

SEO.defaultProps = {
  title: '',
  description: '',
  keywords: '',
  image: '',
  lang: 'en',
  pathname: '',
  isTemplatePage: false
};

export default SEO;
